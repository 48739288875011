const autoNgTemplateLoaderTemplate1 = require('./sensitive-details.html');

import angular from 'angular';
import './sensitive-details.css';

angular.module('portainer.app').component('sensitiveDetails', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    key: '@',
    value: '@',
  },
});
