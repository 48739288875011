const autoNgTemplateLoaderTemplate1 = require('./ldap-custom-admin-group.html');

import './ldap-custom-admin-group.css';
import controller from './ldap-custom-admin-group.controller';

export const ldapCustomAdminGroup = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    settings: '=',
    selectedAdminGroups: '=',
    defaultAdminGroupSearchFilter: '<',
    onSearchClick: '<',
    limitedFeatureId: '<',
    isLimitedFeatureSelfContained: '<',
  },
};
