const autoNgTemplateLoaderTemplate1 = require('./registriesDatatable.html');

angular.module('portainer.app').component('registriesDatatable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'RegistriesDatatableController',
  bindings: {
    titleText: '@',
    titleIcon: '@',
    dataset: '<',
    tableKey: '@',
    orderBy: '@',
    reverseOrder: '<',
    removeAction: '<',
    canBrowse: '<',
    endpointType: '<',
    canManageAccess: '<',
  },
});
