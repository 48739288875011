const autoNgTemplateLoaderTemplate1 = require('./logViewer.html');

angular.module('portainer.docker').component('logViewer', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'LogViewerController',
  bindings: {
    data: '=',
    displayTimestamps: '=',
    logCollectionChange: '<',
    sinceTimestamp: '=',
    lineCount: '=',
    resourceName: '<',
  },
});
