const autoNgTemplateLoaderTemplate1 = require('./resourceReservation.html');

angular.module('portainer.kubernetes').component('kubernetesResourceReservation', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesResourceReservationController',
  bindings: {
    description: '@',
    cpuReservation: '<',
    cpuUsage: '<',
    cpuLimit: '<',
    memoryReservation: '<',
    memoryUsage: '<',
    memoryLimit: '<',
    displayUsage: '<',
  },
});
