const autoNgTemplateLoaderTemplate1 = require('./edgeStackStatus.html');

import angular from 'angular';

import { EdgeStackStatusController } from './edgeStackStatusController';
import './edgeStackStatus.css';

angular.module('portainer.edge').component('edgeStackStatus', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: EdgeStackStatusController,
  bindings: {
    stackStatus: '<',
  },
});
