const autoNgTemplateLoaderTemplate1 = require('./ldap-settings-custom.html');

import './ldap-settings-custom.css';
import controller from './ldap-settings-custom.controller';

export const ldapSettingsCustom = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    settings: '=',
    tlscaCert: '=',
    state: '=',
    onTlscaCertChange: '<',
    connectivityCheck: '<',
    onSearchUsersClick: '<',
    onSearchGroupsClick: '<',
    onSaveSettings: '<',
    saveButtonState: '<',
    saveButtonDisabled: '<',
  },
};
