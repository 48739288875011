const autoNgTemplateLoaderTemplate1 = require('./ldap-settings-test-login.html');

import './ldap-settings-test-login.css';
import controller from './ldap-settings-test-login.controller';

export const ldapSettingsTestLogin = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    settings: '=',
    limitedFeatureId: '<',
    showBeIndicatorIfNeeded: '<',
    isLimitedFeatureSelfContained: '<',
  },
};
